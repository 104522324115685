<!--
 * @Author: sqk
 * @Date: 2020-08-12 10:40:56
 * @LastEditTime: 2022-01-29 14:41:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Carousel\Carousel.vue
-->

<template>
<!-- :class="bgType" -->
<!-- :style="{ backgroundImage:'url(' + require('./imgs/' + bgType + '.png') + ')'}" -->
<div>
      <audio id="musicfx" loop="loop" preload="auto" autoplay="autoplay" muted>
        <source src="./imgs/bgMusic.mp3" type="audio/mpeg" id="hhh">
    </audio>
    <div id="nb-box" class="swiper-container" ref="mySwiper"  >
    <div class="bg" :style="{ backgroundImage:'url(' + require('./imgs/bg22.png') + ')'}" data-swiper-parallax="-23%" data-swiper-parallax-duration="3000"></div>
    <div class="swiper-wrapper">
    <!-- 1 -->
      <div class="swiper-slide">
        <auto-view
          :wh="[291, 156]"
          psn="absolute"
          :ps="[73, 0, 0, 42]"
          class="ani"
          swiper-animate-effect="pulse"
          swiper-animate-duration="2s"
          swiper-animate-delay="0.3s"
        >
          <img src="./imgs/1-word-2.png" alt />
        </auto-view>
        <auto-view
          :wh="[293, 283]"
          psn="absolute"
          :ps="[323, 0, 0, 41]"
          class="ani"
          swiper-animate-effect="flipInY"
          swiper-animate-duration="2s"
          swiper-animate-delay="0.5s"
        >
          <img src="./imgs/1-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[120, 94]"
          psn="absolute"
          :ps="[372, 0, 0, 0]"
          class="ani"
          swiper-animate-effect="fadeInDown"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/1-left.png" alt />
        </auto-view>
        <auto-view
          :wh="[150, 118]"
          psn="absolute"
          :ps="[464, 7, 0, 0]"
          class="ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/1-right.png" alt />
        </auto-view>
        <auto-view
          :wh="[170, 58]"
          psn="absolute"
          :ps="[0, 0, 38, 102]"
          class="ani"
          swiper-animate-effect="fadeIn"
          swiper-animate-duration="3s"
          swiper-animate-delay="1s"
          @click="slideNext"
        >
          <img src="./imgs/1-btn.png" alt />
        </auto-view>
      </div>
      <!-- 2 -->
      <div class="swiper-slide">
        <auto-view
          :wh="[256, 57]"
          psn="absolute"
          :ps="[48, 0, 0, 32]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <div class="content-word" v-if="reportData && reportData.shopStartYear == 2021">
            <p>在不平凡但充满力量的 <em>{{reportData.shopStartYear}}</em>，祝贺您加入了海信专卖店大家庭！</p>
        </div>
         <div class="content-word" v-if="reportData && reportData.shopStartYear != 2021">
            <p><em>{{reportData.shopStartYear}}</em>年<em>{{reportData.shopStartMonth}}</em>月至今，经营海信专卖店的<i>{{reportData.shopStartDaynum}}</i>个日夜中~</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[256, 138]"
          psn="absolute"
          :ps="[131, 0, 0, 32]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s"
        >
        <div class="content-word" v-if="reportData && reportData.shopStartYear == 2021">
            <p>距今您已经营海信专卖店<i>{{reportData.shopStartDaynum}}</i>天。不积跬步无以至千里，建百年海信，成为全球最值得信赖的品牌，感谢您的信任和支持!</p>
        </div>
       <div class="content-word" v-if="reportData && reportData.shopStartYear != 2021">
            <p>是您的不懈努力与不断付出造就了海信今日的辉煌。建百年海信，成为全球最值得信赖的品牌， 感谢您一路的信任和支持!</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 285]"
          psn="absolute"
          :ps="[0, 0, 60, 0]"
          class="ani"
          swiper-animate-effect="bounceIn"
          swiper-animate-duration="1s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/2-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[176, 146]"
          psn="absolute"
          :ps="[0, 16, 51, 0]"
          class="ani"
          swiper-animate-effect="bounceInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="0.6s"
        >
          <img src="./imgs/2-right.png" alt />
        </auto-view>
      </div>
      <!-- 2-2 -->
      <!-- <div class="swiper-slide">
        <auto-view
          :wh="[256, 57]"
          psn="absolute"
          :ps="[48, 0, 0, 32]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <div class="content-word">
            <p><em>2019</em>年<em>1</em>月至今，经营海信专卖店的<i>999</i>个日夜中~</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[256, 138]"
          psn="absolute"
          :ps="[138, 0, 0, 32]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s"
        >
        <div class="content-word">
            <p>是您的不懈努力与不断付出造就了海信今日的辉煌。建百年海信，成为全球最值得信赖的品牌， 感谢您一路的信任和支持!</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 285]"
          psn="absolute"
          :ps="[0, 0, 60, 0]"
          class="ani"
          swiper-animate-effect="bounceIn"
          swiper-animate-duration="1s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/2-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[176, 146]"
          psn="absolute"
          :ps="[0, 16, 51, 0]"
          class="ani"
          swiper-animate-effect="bounceInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="0.6s"
        >
          <img src="./imgs/2-right.png" alt />
        </auto-view>
      </div> -->
      <!-- 3 -->
      <div class="swiper-slide" v-if="reportData && reportData.loginTimes && reportData.loginTimes > 4">
        <auto-view
          :wh="[272, 64]"
          psn="absolute"
          :ps="[48, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <!-- ，共计<i>835</i>分。 -->
        <div class="content-word">
            <p><em>2021</em>年，信天翁持续助力您的门店经营，累计登陆<i>{{reportData.loginTimes}}</i>次。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[272, 71]"
          psn="absolute"
          :ps="[140, 0, 0, 27]"
          v-if="reportData && reportData.lastLoginHour < 6 || reportData && reportData.lastLoginHour > 18"
        >
        <div class="content-word more-view">
            <p class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s">海信信天翁充满您的日常</p>
            <p class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="1.2s"><em>{{reportData.lastLoginMonth}}</em>月<em>{{reportData.lastLoginDay}}</em>日，<em>{{reportData.lastLoginHour}}</em>点的您仍在登陆使用。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 285]"
          psn="absolute"
          :ps="[0, 0, 60, 0]"
          class="ani"
          swiper-animate-effect="fadeInDown"
          swiper-animate-duration="2s"
          swiper-animate-delay="1.2s"
        >
          <img src="./imgs/4-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[76, 156]"
          psn="absolute"
          :ps="[0, 0, 70, 224]"
          class="ani"
          swiper-animate-effect="fadeInRight"
          swiper-animate-duration="2s"
          swiper-animate-delay="1.2s"
        >
          <img src="./imgs/4-right.png" alt />
        </auto-view>
      </div>
      <!-- 4 -->
      <div class="swiper-slide">
        <auto-view
          :wh="[256, 96]"
          psn="absolute"
          :ps="[50, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <!-- 学习次数  0 ? -->
        <div class="content-word" >
            <p v-show="reportData.studyTimeSum == 0 || reportData.studyTimeSum == null">这一年里您从未使用过信天翁海信学堂，学习可以帮助我们更好的提升门店日常运营。</p>
            <p v-show="reportData.studyTimeSum > 0">这一年里您通过使用过信天翁海信学堂，学习时长<i>{{reportData.studyTimeSum}}</i>分钟，累计获得<i>{{reportData.studyScore}}</i>学分。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[256, 96]"
          psn="absolute"
          :ps="[168, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s"
        >
        <div class="content-word">
            <p v-show="reportData.studyTimeSum == 0 || reportData.studyTimeSum == null">快来<i>xtw.hisense.com</i>下载信天翁加入海信大家庭吧！我们在信天翁海信学堂里等您！</p>
            <p v-show="reportData.studyTimeSum > 0">一年来收货满满。不断的学习，不断的成长，才会使门店经营的更出色。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 288]"
          psn="absolute"
          :ps="[0, 0, 70, 0]"
          class="ani"
          swiper-animate-effect="swing"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/5-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[88, 168]"
          psn="absolute"
          :ps="[0, 0, 48, 269]"
          class="ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/5-right.png" alt />
        </auto-view>
      </div>
      <!-- 4-2 -->
      <!-- <div class="swiper-slide">
        <auto-view
          :wh="[259, 96]"
          psn="absolute"
          :ps="[48, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <div class="content-word">
            <p>这一年里您通过使用过信天翁海信学堂，学习时长<i>234</i>分钟，累计获得<i>88</i>学分。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[259, 96]"
          psn="absolute"
          :ps="[168, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s"
        >
        <div class="content-word">
            <p>一年来收货满满。不断的学习，不断的成长，才会使门店经营的更出色。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 286]"
          psn="absolute"
          :ps="[0, 0, 74, 0]"
          class="ani"
          swiper-animate-effect="fadeInDown"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/6-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[92, 172]"
          psn="absolute"
          :ps="[0, 0, 52, 230]"
          class="ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/6-right.png" alt />
        </auto-view>
      </div> -->
      <!-- 5 -->
      <div class="swiper-slide">
        <auto-view
          :wh="[259, 132]"
          psn="absolute"
          :ps="[48, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <div class="content-word">
            <p>这一年，在有CIS记录的这段岁月里，多亏您的倾尽全力，一共服务了<i>{{reportData.customerNum}}</i>个用户，因为您，用户选择了相信海信的产品。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[259, 96]"
          psn="absolute"
          :ps="[204, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s"
        >
        <div class="content-word">
            <p><em>{{$moment(reportData.goodDay).format("MM")}}</em>月<em>{{$moment(reportData.goodDay).format("DD")}}</em>日，是销售最好的一天，共有<i>{{reportData.goodDayCustomerNum}}</i>个用户选择海信。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 286]"
          psn="absolute"
          :ps="[0, 0, 74, 0]"
          class="ani"
          swiper-animate-effect="fadeInDown"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/6-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[92, 172]"
          psn="absolute"
          :ps="[0, 0, 52, 230]"
          class="ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/6-right.png" alt />
        </auto-view>
      </div>
      <!-- 8 -->
      <div class="swiper-slide">
        <auto-view
          :wh="[289, 64]"
          psn="absolute"
          :ps="[48, 0, 0, 27]"
        >
        <div class="content-word">
            <p   class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"><em>2021</em>年门店销售结构：</p>
            <p   class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.5s">中高端占比<i class="space">{{reportData.highRate ? (reportData.highRate*100).toFixed(2) : 0}}</i>%</p>
            <p   class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.7s">专供产品占比<i class="space">{{reportData.specialRate ? (reportData.specialRate*100).toFixed(2) : 0}}</i>%</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[259, 90]"
          psn="absolute"
          :ps="[180, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.9s"
        >
        <div class="content-word">
            <p>优化销售结构，提升盈利能力，还需要我们一起努力。而您的努力正是让海信不断前进的最强动力。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 356]"
          psn="absolute"
          :ps="[0, 0, 47, 0]"
          class="ani"
          swiper-animate-effect="fadeInDown"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/7-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[84, 154]"
          psn="absolute"
          :ps="[0, 0, 42, 248]"
          class="ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/7-right.png" alt />
        </auto-view>
      </div>
      <!-- 9 -->
      <div class="swiper-slide">
        <auto-view
          :wh="[259, 71]"
          psn="absolute"
          :ps="[48, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.3s"
        >
        <div class="content-word">
            <p>新的一年，愿君跟勤劳交友，与努力为伴，跟拼搏合作，与收获握手。</p>
        </div>
        </auto-view>
        <auto-view
          :wh="[264, 32]"
          psn="absolute"
          :ps="[127, 0, 0, 27]"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1s"
          swiper-animate-delay="0.8s"
        >
        <div class="content-word">
            <p><i class="big">海信专卖店，专卖好家电。</i></p>
        </div>
        </auto-view>
        <auto-view
          :wh="[375, 294]"
          psn="absolute"
          :ps="[0, 0, 107, 0]"
          class="ani"
          swiper-animate-effect="fadeInDown"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/9-mid.png" alt />
        </auto-view>
        <auto-view
          :wh="[272, 184]"
          psn="absolute"
          :ps="[0, 0, 76, 0]"
          class="ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="2s"
          swiper-animate-delay="1s"
        >
          <img src="./imgs/9-left.png" alt />
        </auto-view>
      </div>
    </div>
    <!-- 下一页 -->
      <auto-view
        :wh="[375, 32]"
        psn="absolute"
        :ps="[0, 0, 16, 0]"
        class="next animated fadeIn"
        v-show="this.bgType == 'bg2'"
    >
        <img src="./imgs/arrow-top.png" alt />
        
        <p>上滑查看下一页</p>
    </auto-view>
    <auto-view
        id="music-btn"
        :wh="[50, 50]"
        psn="absolute"
        :ps="[24, 18, 0, 0]"
        class="music-icon"
        :class="{active:isMusicPlay}"
        @click="musicPlayBtnClick"
        
    >
        <i  class="iconfont icon-a-yinlekaiqi1"/>
    </auto-view>
  </div>
</div>
  
</template>

<script>
import moment from "moment";
import * as swiperAni from "./animate.js";
import autoView from "./RespondBox"; //响应式盒子
// 导入api接口
import { getYearReport } from "./api.js";
export default {
  name: "banner",
  components: {
    autoView
  },
  data() {
    return {
        mySwiper:null,
        bgType:'bg1',
        activeIndex:0,
        reportData:{},
        isMusicPlay:true,
        musicBtnPlayFlag:0
    };
  },
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    baseUrl: {
      type: String,
      default: ""
    }
  },
  watch: {
      activeIndex(){
           if(this.mySwiper.activeIndex != 0 && this.mySwiper.activeIndex !=($('#nb-box .swiper-slide').length -1)){
                this.bgType = 'bg2';
            }else{
                this.bgType = 'bg1';
            }
      },
  },
  //定义swiper对象
  computed: {
      userInfo() {
      return this.$store.state.user.userInfo
            ? this.$store.state.user.userInfo
            : localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo"))
            : "";
        },
  },
  mounted() {
      $('#nb-box').css("font-size",  (document.documentElement.clientWidth / 375)*100 + 'px' );
    //   if((this.userInfo && this.userInfo.account && this.userInfo.account.id) || this.userInfo.account.id){
          
    //   }
    // document.documentElement.style.fontSize = (document.documentElement.clientWidth / 375)*100 + 'px';
    this.swiperInit();
    this.getYearReport();
    const this_ = this;
    document.addEventListener('DOMContentLoaded', ()=>{
        this_.musicPlayFun();
    });
    document.addEventListener('click', ()=>{
        if(!this_.musicBtnPlayFlag){
            const music = document.getElementById('musicfx');
            if (music.paused) {
                this.isMusicPlay = true;
                music.play();
            }
        }
    });
    document.addEventListener('touchend', ()=>{
        if(!this_.musicBtnPlayFlag){
            const music = document.getElementById('musicfx');
            if (music.paused) {
                this.isMusicPlay = true;
                music.play();
            }
        }
    });
  },
  methods: {
      getQueryValue(queryName) {
			let reg = `(^|&)${queryName}=([^&]*)(&|$)`
			let r = window.location.hash.substr(13).match(reg);
			if (r != null) return unescape(r[2]);
            return null;
		},
      // 获取活动列表
    getYearReport() {
        let accountId = '';
        if(this.$route.query && this.$route.query.accountId){
            accountId = this.$route.query.accountId
        }
      getYearReport({accountId:accountId})
        .then(res => {
            this.reportData = res.data.list[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally((info) => {
          console.log(info);
        });
    },
    musicPlayBtnClick(){
        const music = document.getElementById('musicfx');
        if (music.paused) {
            this.musicBtnPlayFlag = 2;
        } else {
            this.musicBtnPlayFlag = 1;
        }
        this.musicPlayFun();
    },
    //音乐播放
    musicPlayFun() {
        // alert(123)
      const music = document.getElementById('musicfx');
        if (music.paused) {
            this.isMusicPlay = true;
            music.play();
        } else {
            this.isMusicPlay = false;
            music.pause();
            
        }
    },
      //跳转下一页
    slideNext() {
      this.mySwiper.slideNext()
    },
    borderColorFun(index) {
      let idx = index % 4;
      let colorList = ["#FF8F3C", "#00B7B3", "#4285FF", "#D9161C"];
      return colorList[idx];
    },
    swiperInit() {
      let this_ = this;
      this.$nextTick(() => {
        this.mySwiper = new this.$Swiper(this.$refs.mySwiper, {
          on: {
            init: function() {
              // this_.$swiperAnimateCache(this); //隐藏动画元素
              swiperAni.swiperAnimateCache(this); //隐藏动画元素
              swiperAni.swiperAnimate(this); //初始化完成开始动画
               if(!this_.musicBtnPlayFlag){
                    if(!this_.musicBtnPlayFlag){
                        const music = document.getElementById('musicfx');
                        if (music.paused) {
                            this.isMusicPlay = true;
                            music.play();
                        }
                    }
                }
            },
            slideChange: function() {
                this_.activeIndex = this.activeIndex;
              swiperAni.swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
              //that.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
              if(!this_.musicBtnPlayFlag){
                    if(!this_.musicBtnPlayFlag){
                        const music = document.getElementById('musicfx');
                        if (music.paused) {
                            this.isMusicPlay = true;
                            music.play();
                        }
                    }
                }
           },

          },
          direction: "vertical",
          parallax : true,
          autoplay: false,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
        });
      });
    }
  }
};
</script>
<style lang="less">
.ani {
  // position:absolute
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.swiper-container {
  width: 100vw;
  height: 100vh;
//   background: url("./imgs/bg1.png") no-repeat center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
//   &.bgType1{
//       background: url("./imgs/bg1.png") no-repeat center;
//       background-size: 100% 100%;
//   }
//    &.bgType2{
//       background: url("./imgs/bg2.png") no-repeat center;
//       background-size: 100% 100%;
//   }
.bg{
    position: absolute;
    width: 100%;
    height: 130.43%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.music-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    i{
        font-size: .3em;
        color: rgba(103, 124, 231,.2);
    }
     &.active{
        // background-color: #f60;
        i{
            color: #75b5f7;
        }
    }
}
    .next{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation-delay:1s !important;
        -webkit-animation-delay:1s !important;
        animation-duration: 2s !important;
        img{
            width: .1em;
            height: .1em;
            margin-bottom: .06em;
            -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
            animation-name: hvr-icon-bob-float, hvr-icon-bob;
            -webkit-animation-duration: .3s, 1.5s;
            animation-duration: .3s, 1.5s;
            -webkit-animation-delay: 0s, .3s;
            animation-delay: 0s, .3s;
            -webkit-animation-timing-function: ease-out, ease-in-out;
            animation-timing-function: ease-out, ease-in-out;
            -webkit-animation-iteration-count: 1, infinite;
            animation-iteration-count: 1, infinite;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-direction: normal, alternate;
            animation-direction: normal, alternate;
        }
        p{
            text-align: center;
            font-size: .12em;
            color: #7B8397;
        }
    }
  .swiper-slide {
    width: 100%;
    height: 100% !important;
    img {
      width: 100%;
      height: 100%;
    }
    div.content-word{
        line-height: .3em;
        &.more-view{
             display: flex;
             flex-direction: column;
            align-items: flex-start;
        }
        p{
            color: #35415E;
            font-size: 0.16em;
            text-align: left;
            &.ani{
                align-items: baseline;
            }
            em{
                // font-size: 0.16em;
                // padding: 0 .02em;
                color: #FC5260;
            }
            i{
                font-size: 1.375em;
                // padding: 0 .02em;
                color: #00B7B3;
                &.space{
                padding: 0 .2em;
                }
                 &.big{
                    font-size: 1.2em;
                }
            }
        }
    }
  }
}
</style>

